import { FeatureGroup, GeoJSON, Popup } from "react-leaflet";
import { IGeoJSONFeature } from "../../common-types/geoserver";
import { Table } from "react-bootstrap";

interface IGeoserverLyr {
  properties: any;
}

export const PopupTable = (props: IGeoserverLyr) => {
  //console.log("PopupTable");
  //console.log(props.properties);
  return (
    <Popup>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Показатель</th>
            <th>Значение</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(props.properties).map((propertie: any) => {
            const usePropertie =
              propertie == "COLORS" ||
              propertie == "match_fiel" ||
              propertie == "Index" ||
              propertie == "OBJECTID" ||
              propertie == "Objectid" ||
              propertie == "SHAPE_Area" ||
              propertie == "SHAPE_Leng";
            if (usePropertie === false) {
              return (
                <tr>
                  <td>{propertie}</td>
                  <td>{props.properties[propertie]}</td>
                </tr>
              );
            } else {
              return null;
            }
          })}
        </tbody>
      </Table>
    </Popup>
  );
};
