import React from "react";
import { Chrono } from "react-chrono";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actionLevel } from "../../Toolbox/Cards/Basemap/basemapActions";
import { actionSetDataset } from "../../Toolbox/Cards/Basemap/basemapActions";
import { actionAddBasemaps } from "../../Map/basemaps/actions/actionBasemaps";
import { actionSetYear } from "../historical-date-change/actionSetYear";

const BasemapTimeline: React.FC = (props: any) => {
  const onDataSelect = (selected: any) => {
    props.setDataset(selected.table_name);
    props.setYear(Number(selected.year));
    if (selected.year < 2000) {
      props.setBasemap("https://geo.emg24.ru/geoserver/historical/wms", false);
      props.setLevel("M05");
    } else {
      props.setBasemap("https://geo.emg24.ru/geoserver/baselayers/wms", false);
    }
  };
  const data = [
    {
      year: 2022,
      table_name: "y2022_sep_30",
      title: "30 сентября 2022",
      cardTitle: "ДНР, ЛНР, Херсонская и Запорожская области",
      cardSubtitle:
        "Присоединение территорий Донецкой, Луганской, Запорожской и Херсонской областей",
      cardDetailedText: `В состав Херсонской области вошли Снигирёвский и Александровский округа Николаевской области.
      Столицей Запорожской области стал Мелитополь.`,
    },
    {
      year: 2022,
      table_name: "y2022_sep_29",
      title: "29 сентября 2022",
      cardTitle: "Херсонская и Запорожская области",
      cardSubtitle: "Признание независимости Херсонской и Запорожской областей",
      cardDetailedText: `Столица Запорожья — Мелитополь.`,
    },
    {
      year: 2022,
      table_name: "y2022_feb_21",
      title: "21 февраля 2022",
      cardTitle: "ЛНР и ДНР",
      cardSubtitle: `Признание независимости ЛНР и ДНР`,
      cardDetailedText: `Вечером 21 февраля Путин подписал два указа — о признании независимости Донецкой и Луганской народных республик и два договора — о дружбе и взаимопомощи.`,
    },
    {
      year: 2014,
      table_name: "y2014_mar_21",
      title: "21 марта 2014",
      cardTitle: "Крым",
      url: "https://tass.ru/info/6222164",
      cardSubtitle: `Присоединение Крыма к Российской Федерации`,
      cardDetailedText: `17 марта 2014 года президент России подписал указ о провозглашении Крыма суверенным
      и независимым государством. 18 марта подписан Договор о принятии Республики Крым в состав Российской Федерации.
      21 марта Путин подписал закон о ратификации договора, а также конституционный закон о принятии
      Республики Крым и Севастополя в состав РФ.`,
    },
    {
      year: 2011,
      table_name: "y2011_jul_09",
      title: "9 июля 2011",
      cardTitle: "Южный Судан",
      url: "https://ru.wikipedia.org/wiki/Южный_Судан",
      cardSubtitle: `Южный Судан отделился от Судана`,
      cardDetailedText: `С 9 по 15 января 2011 года в Южном Судане прошёл референдум по вопросу 
      независимости от Судана. Результаты референдума были объявлены 7 февраля. Официальное провозглашение 
      нового государства произошло 9 июля 2011 года. Столицей стал город Джуба.`,
    },
    {
      year: 2008,
      table_name: "y2008_aug_26",
      title: "26 августа 2008",
      cardTitle: "Абхазия и Южная Осетия",
      //cardSubtitle: `Южный Судан отделился от Судана`,
      cardDetailedText: `Признание Россией независимости Абхазии и Северной Осетии.`,
    },
    {
      year: 1940,
      table_name: "y1940_cntpol",
      title: "1940",
      cardTitle: "Итоги Польского похода",
      //cardSubtitle: ``,
      cardDetailedText: `После Польского похода 1939 года в состав Белоруссии и Украины вернулись 
      западные части этих республик, оккупированные Польшей в период Гражданской войны в России и 
      Советско-Польской войны 1920 года. СССР приобрёл 196 000 квадратных километров с 13 млн населения. 
      Литве был передан Вильнюс, древняя столица — 6909 квадратных километров, 490 тысяч человек.`,
    },
    {
      year: 1939,
      table_name: "y1939_cntpol",
      title: "1939",
      cardTitle: "Оккупация Чехословакии",
      cardDetailedText: `Оккупация Германией Чехословакии, на территории которой образован 
      Протекторат Чехии и Моравии и марионеточное государство Словакия. Захват Германией Клайпеды и 
      Клайпедской области.
      Приход к власти в Испании генерала Франко, установление фашистской диктатуры.
      Албания захвачена Италией и объявлена колонией, включена в Итальянскую империю.`,
    },
    {
      year: 1938,
      table_name: "y1938_cntpol",
      title: "1938",
      cardTitle: "Захват Австрии",
      cardDetailedText: `Германия захватила Австрию, включив ее в состав Третьего рейха 
        под названием «Остмарк».
        Мюнхенское соглашение: раздел Чехословакии (к Германии отошли Судетская область и другие 
        приграничные районы, к Польше — Тешинская область, к Венгрии — часть Словакии и Закарпатская Украина).`,
    },
    {
      year: 1937,
      table_name: "y1937_cntpol",
      title: "1937",
      cardTitle: "Ирландия",
      cardSubtitle: `Начало отделения Ирландии от Великобритании`,
      cardDetailedText: `Ирландия объявила себя независимым государством Эйре, 
      являясь при этом доминионом Великобритании. Официально провозглашена независимой в 1949 году.`,
    },
    {
      year: 1935,
      table_name: "y1935_cntpol",
      title: "1935",
      cardTitle: "Германия",
      cardDetailedText:
        "Присоединение Саара к Германии. Монархический переворот в Греции.",
    },
    {
      year: 1934,
      table_name: "y1934_cntpol",
      title: "1934",
    },
    {
      year: 1933,
      table_name: "y1933_cntpol",
      title: "1933",
      cardTitle: "Германия",
      cardDetailedText: "Приход нацизма к власти в Германии.",
    },
    {
      year: 1932,
      table_name: "y1932_cntpol",
      title: "1932",
    },
    {
      year: 1930,
      table_name: "y1930_cntpol",
      title: "1930",
    },
    {
      year: 1920,
      table_name: "y1920_cntpol",
      title: "1920",
      cardTitle: "Распад Османской империи",
      cardSubtitle: `Латвия получила независимость`,
      cardDetailedText: `Архипелаг Шпицберген перешел под суверенитет Норвегии. Получила независимость Латвия. 
      По Трианонскому договору к Румынии отошли Трансильвания и южная часть области Банат; 
      к Чехословакии — Словакия и Закарпатская Украина; к Австрии — Бургенланд, Словенская Каринтия. 
      Распад Османской империи: к Италии отошли острова Додеканес (Южные Спорады), 
      к Греции — Восточная Фракия с Адрианополем (ныне город Эдирне в Турции), 
      Галлипольский полуостров и Смирна (ныне город Измир в Турции). 
      По Рапаллъскому договору между Италией и Королевством сербов, хорватов и словенцев 
      к Италии перешли Юлийская Краина (область Фриули-Венеция — Джулия), 
      полуостров Истрия с городами Триест и Пула, острова Лошинь, Црес, 
      Ластово в центре Адриатического моря; к Югославии — Словения, Далмация, Босния и Герцеговина. 
      Порт Зара приобрел статус вольного города под суверенитетом Италии, 
      Фиуме (Риека) стал вольным городом. Польша захватила у Литвы Вилен.`,
    },
    {
      year: 1918,
      table_name: "y1918_cntpol",
      title: "1918",
      cardTitle: "Распад Австро-Венгрии",
      cardSubtitle: `Независимая Польша, образование Чехословакии, Австрии, Венгрии, Королевства сербов, хорватов и словенцев`,
      cardDetailedText: `Распад Австро-Венгерской монархии, образовались: 
      Чехословакия (ей переданы австрийские «коронные земли» — Богемия, Моравия, Силезия), 
      Австрия и Венгрия; к Италии перешел Южный Тироль, к Румынии — Буковина.

      Образование Королевства сербов, хорватов и словенцев 
      (объединены Сербия, Черногория и бывшие южнославянские территории Австро-Венгрии — Хорватия, 
        Словения, Далмация и часть Боснии и Герцеговины).
      
      Свержение монархии в Германии.
      
      Получила независимость Польша.`,
    },
    {
      year: 1917,
      table_name: "y1917_cntpol",
      title: "1917",
      cardTitle: "Россия, Финляндия",
      cardDetailedText: `Финляндия получила независимость. В результате революции в России ликвидирована монархия.`,
    },
    {
      year: 1913,
      table_name: "y1913_cntpol",
      title: "1913",
    },
    {
      year: 1906,
      table_name: "y1906_cntpol",
      title: "1906",
    },
    {
      year: 1893,
      table_name: "y1893_cntpol",
      title: "1893",
    },
  ];

  return (
    <Chrono
      items={data}
      mode="VERTICAL"
      fontSizes={{
        cardSubtitle: "0.8rem",
        cardText: "0.6rem",
        cardTitle: "0.85rem",
        title: "0.8rem",
      }}
      onItemSelected={onDataSelect}
    />
  );
};

const mapStateToProps = (state: any) => {
  return {
    layers: state.layers,
  };
};
const matchDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      setLevel: actionLevel,
      setDataset: actionSetDataset,
      setBasemap: actionAddBasemaps,
      setYear: actionSetYear,
    },
    dispatch
  );
};
export default connect(mapStateToProps, matchDispatchToProps)(BasemapTimeline);
