import React, { useState } from "react";
import { Button, ButtonGroup, Card, Form, InputGroup, FormControl, ToggleButton } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { pushButton } from "./vyboryActions/buttonPush";
import { actionArea } from "./vyboryActions/areaChoose";
import { actionStyle } from "./vyboryActions/styleChoose";
import { actionValue } from "./vyboryActions/valueChoose";
import { actionScale } from "./vyboryActions/scaleChoose";
import { actionColors } from "./vyboryActions/colorChoose";
import { actionCheckbox } from "./vyboryActions/checkboxChange";
import { useSelector } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";

const Vybory: React.FC = (props: any) => {
  const [checked, setChecked] = useState(props.layers[12].downloadChecked);

  const [radioValue, setRadioValue] = useState('rik');

  const radios = [
    { name: 'РИК', value: 'rik' },
    { name: 'М24', value: 'msk' },
  ];

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const users = useSelector((state) => props.users);

  const buttonClick = (e: any) => {
    props.setPushButton(true);
    const token = users.currentUser.token;
    const headers = {
      token: token,
    };

    axios
      .post("/api/election", props.layers[12], {
        headers: headers,
      })
      .then((r) => {
        alert(
          "Готово! Все материалы во вкладке Задачи"
        );
        props.setPushButton(false);
      })
      .catch((error) => {
        props.setPushButton(false);
        alert(`Election error!`);
      });
  };

  const styleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    switch (e.target.value) {
      case "Явка":
        props.setStyle("presence");
        props.setColors([
          "#c9cfec",
          "#82a0dd",
          "#4d58f4",
          "#030fae",
          "#000256",
        ]);
        props.setArea("presence");
        if (props.layers[12].downloadChecked === true) {
          props.setPushButton(false);
        }
        break;
      case "Новые люди (Даванков)":
        //setIsRIKPresence(false);
        props.setStyle("Даванков");
        props.setColors([
          "#bff2f1",
          "#9ae5e4",
          "#47c2c0",
          "#018785",
          "#005c5b",
        ]);
        //props.setColors({0:'#f0c8c8', 1:'#ff9393', 2: '#f43729', 3: '#c40000', 4:'#860202'});
        props.setArea("candidates");
        if (props.layers[12].downloadChecked === true) {
          props.setPushButton(false);
        }
        break;
      case "Самовыдвижение (Путин)":
        props.setStyle("Путин");
        props.setColors([
          "#bfbfea",
          "#9b9bdc",
          "#6363b8",
          "#36368f",
          "#000050",
        ]);
        //props.setColors({0:'#faedd5', 1:'#ffe084', 2: '#ffb400', 3: '#f97c00', 4:'#d75701'});
        props.setArea("candidates");
        if (props.layers[12].downloadChecked === true) {
          props.setPushButton(false);
        }
        break;
      case "ЛДПР (Слуцкий)":
        props.setStyle("Слуцкий");
        props.setColors([
          "#beddfb",
          "#87bdf4",
          "#4488cc",
          "#175da4",
          "#003d71",
        ]);
        //props.setColors({0:'#d2e7fc', 1:'#87bdf4', 2: '#308ce8', 3: '#015dac', 4:'#003a66'});
        props.setArea("candidates");
        if (props.layers[12].downloadChecked === true) {
          props.setPushButton(false);
        }
        break;
      case "КПРФ (Харитонов)":
        props.setStyle("Харитонов");
        props.setColors([
          "#fac1c1",
          "#ff9393",
          "#e14242",
          "#cc1414",
          "#a60303",
        ]);
        //props.setColors({0:'#d0f8d0', 1:'#83eb83', 2: '#33cc33', 3: '#009000', 4:'#075f00'});
        props.setArea("candidates");
        if (props.layers[12].downloadChecked === true) {
          props.setPushButton(false);
        }
        break;
      case "Победитель №1 в регионе":
        props.setStyle("leader_1");
        props.setArea("candidates");
        if (props.layers[12].downloadChecked === true) {
          props.setPushButton(false);
        }
        break;
      case "Победитель №2 в регионе":
        props.setStyle("leader_2");
        props.setArea("candidates");
        if (props.layers[12].downloadChecked === true) {
          props.setPushButton(false);
        }
        break;
      case "Победитель №3 в регионе":
        props.setStyle("leader_3");
        props.setArea("candidates");
        if (props.layers[12].downloadChecked === true) {
          props.setPushButton(false);
        }
        break;
    }
  };

  const checkboxCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    switch (props.layers[12].csv_om) {
      case false:
        props.setCheckbox(true);
        if (props.layers[12].downloadChecked === true) {
          props.setPushButton(false);
        }
        break;
      case true:
        props.setCheckbox(false);
        if (props.layers[12].downloadChecked === true) {
          props.setPushButton(false);
        }
    }
  };
  const scaleChange1 = (e: React.ChangeEvent<HTMLInputElement>) => {
    var scale = props.layers[12].scale;
    scale[0] = Number(e.target.value);
    props.setScale(scale);
    if (props.layers[12].downloadChecked === true) {
      props.setPushButton(false);
    }
  };
  const scaleChange2 = (e: React.ChangeEvent<HTMLInputElement>) => {
    var scale = props.layers[12].scale;
    scale[1] = Number(e.target.value);
    props.setScale(scale);
    if (props.layers[12].downloadChecked === true) {
      props.setPushButton(false);
    }
  };
  const scaleChange3 = (e: React.ChangeEvent<HTMLInputElement>) => {
    var scale = props.layers[12].scale;
    scale[2] = Number(e.target.value);
    props.setScale(scale);
    if (props.layers[12].downloadChecked === true) {
      props.setPushButton(false);
    }
  };
  const scaleChange4 = (e: React.ChangeEvent<HTMLInputElement>) => {
    var scale = props.layers[12].scale;
    scale[3] = Number(e.target.value);
    props.setScale(scale);
    if (props.layers[12].downloadChecked === true) {
      props.setPushButton(false);
    }
  };
  const colorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    var colors = props.layers[12].colors;
    colors[Number(e.target.id)] = e.target.value;
    props.setColors(colors);
    if (props.layers[12].downloadChecked === true) {
      props.setPushButton(false);
    }
  };

  const is_leader = props.layers[12].value.startsWith('leader');

  const is_presence = props.layers[12].data_type == 'presence';

  const is_msk_presence = radioValue == 'msk' && props.layers[12].data_type == 'presence';

  const handleRadioChange = (radio_value: string) => {
    setRadioValue(radio_value);
    if (radio_value == 'msk') {
      var scale = props.layers[12].scale;
      scale = [30, 40, 50]
      props.setScale(scale);
      props.setColors([
        "#6ad3f3",
        "#5eadfc",
        "#2858bc",
        "#0e3379",
      ]);
    }
    else {
      props.setScale([2, 5, 10, 15]);
      props.setColors([
        "#c9cfec", "#82a0dd", "#4d58f4", "#030fae", "#000256"]);
    }
  }
  return (
    <div className="vybory">
      <Card.Body>
      <ButtonGroup className="mb-2">
        {radios.map((radio, idx) => (
          <ToggleButton
            key={idx}
            id={`radio-${idx}`}
            type="radio"
            variant="secondary"
            name="radio"
            value={radio.value}
            checked={radioValue === radio.value}
            disabled={!is_presence}
            onChange={(e) => handleRadioChange(e.currentTarget.value)}
          >
            {radio.name}
          </ToggleButton>
        ))}
      </ButtonGroup>
        <Form.Group controlId="exampleForm.ControlSelect2">
          <Form.Label>Данные по</Form.Label>
          <Form.Control size="sm" as="select" onChange={styleChange}>
            <option>Явка</option>
            {!is_msk_presence ?
            <>
            <option>КПРФ (Харитонов)</option>
            <option>ЛДПР (Слуцкий)</option>
            <option>Новые люди (Даванков)</option>
            <option>Самовыдвижение (Путин)</option>
            <option>Победитель №1 в регионе</option>
            <option>Победитель №2 в регионе</option>
            </> : null}
          </Form.Control>
        </Form.Group>
        {!is_leader ? 
        <>
        <Form.Group controlId="exampleForm.ControlSelect3">
          <Form.Label>Шкала</Form.Label>
        </Form.Group>
        <InputGroup className="mb-3" size="sm">
          <InputGroup.Prepend>
            <InputGroup.Text>менее</InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            placeholder={props.layers[12].scale[0]}
            onChange={scaleChange1}
          />
          <FormControl
            placeholder={props.layers[12].scale[1]}
            onChange={scaleChange2}
          />
          <FormControl
            placeholder={props.layers[12].scale[2]}
            onChange={scaleChange3}
          />
          {!is_msk_presence ? 
          <FormControl
            placeholder={props.layers[12].scale[3]}
            onChange={scaleChange4}
          /> : null}
          <InputGroup.Prepend>
            <InputGroup.Text>более</InputGroup.Text>
          </InputGroup.Prepend>
        </InputGroup>
        <Form.Label htmlFor="exampleColorInput">Цветовая шкала</Form.Label>
        <InputGroup className="mb-3">
          <Form.Control
            type="color"
            id="0"
            value={props.layers[12].colors[0]}
            defaultValue={props.layers[12].colors[0]}
            title="Выберите цвет"
            onChange={colorChange}
          />
          <Form.Control
            type="color"
            id="1"
            value={props.layers[12].colors[1]}
            defaultValue={props.layers[12].colors[1]}
            title="Выберите цвет"
            onChange={colorChange}
          />
          <Form.Control
            type="color"
            id="2"
            value={props.layers[12].colors[2]}
            defaultValue={props.layers[12].colors[2]}
            title="Выберите цвет"
            onChange={colorChange}
          />
          <Form.Control
            type="color"
            id="3"
            value={props.layers[12].colors[3]}
            defaultValue={props.layers[12].colors[3]}
            title="Выберите цвет"
            onChange={colorChange}
          />
          {!is_msk_presence ? 
          <Form.Control
            type="color"
            id="4"
            value={props.layers[12].colors[4]}
            defaultValue={props.layers[12].colors[4]}
            title="Выберите цвет"
            onChange={colorChange}
          /> : null}
        </InputGroup>
        </> : null }

        <Form.Group controlId="formBasicCheckboxVyboryCSV">
          <Form.Check
            type="checkbox"
            label="csv для Overmap"
            onChange={checkboxCheck}
          />
        </Form.Group>
        <Button
          size="sm"
          type="button"
          disabled={props.layers[12].downloadChecked}
          onClick={buttonClick}
        >
          Скачать
        </Button>
      </Card.Body>
    </div>
  );
};
const mapStateToProps = (state: any) => {
  return {
    layers: state.layers,
    users: state.users,
  };
};
const matchDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      setPushButton: pushButton,
      setArea: actionArea,
      setStyle: actionStyle,
      setValue: actionValue,
      setCheckbox: actionCheckbox,
      setScale: actionScale,
      setColors: actionColors,
    },
    dispatch
  );
};

export default connect(mapStateToProps, matchDispatchToProps)(Vybory);
