import React, { useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import SpinnerForLoading from "../../../additional/Spinner/SpinnerForLoading";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { pushButton } from "./tempAction/buttonPush";
import { actionTempLyr } from "./tempAction/templyrAdd";
import { actionZoom } from "../Radar/radarActions/changeZoom";
import { actionCenter } from "../Radar/radarActions/changeCenter";
import { actionCheckbox } from "./tempAction/checkboxChange";
import { useSelector } from "react-redux";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";

const Temperature: React.FC = (props: any) => {
  const [checked, setChecked] = useState(props.layers[6].downloadChecked);

  const users = useSelector((state) => props.users);

  const buttonClick = (e: any) => {
    props.setPushButton(true);

    const token = users.currentUser.token;
    const headers = {
      token: token,
    };

    axios
      .post(
        `/api/temperature?cloud_render=${props.layers[10].cloud_render}`,
        props.layers[10],
        {
          headers: headers,
        }
      )
      .then((r) => {
        alert(
          "Все материалы лежат по адресу:\nW:\\Big_Project\\__MAPS\\!Py\\_ATMO_TEMPERATURE"
        );
        props.setPushButton(false);
        props.setTempLyrAdd(true);
        props.setMapZoom(9);
        props.setMapCenter([55.62, 37.6]);
      })
      .catch((error) => {
        props.setPushButton(false);
        alert(`Temperature error! ${error.response.data.error}`);
      });
  };
  const checkboxCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    switch (props.layers[10].cloud_render) {
      case false:
        props.setCheckbox(true);
        break;
      case true:
        props.setCheckbox(false);
    }
    console.log(props.layers[10]);
  };

  return (
    <div className="temperature">
      <Card.Body>
        <h6>
          После нажатия кнопки ОК во всплывающем окне результат добавится на
          карту.
        </h6>
        <h6>При клике на слой отобразится температура выбранного контура.</h6>
        <h6>
          После перезагрузки страницы карта исчезнет. Найти результат можно тут:
        </h6>
        <h6>\__MAPS\!Py\_ATMO_TEMPERATURE</h6>
        <Form.Group controlId="formBasicCheckbox">
          <Form.Check
            type="checkbox"
            label="выгрузить под облачный рендер"
            onChange={checkboxCheck}
          />
        </Form.Group>
        <Button
          size="sm"
          type="button"
          variant="light"
          href="https://docs.google.com/document/d/18RHiVfs9FsDiyMbrhPLmyRtW_FVVZiECV5x3_yNXPR0/edit?usp=sharing"
          target="_blank"
        >
          Инструкция
        </Button>
        <Button
          size="sm"
          type="button"
          disabled={props.layers[10].downloadChecked}
          onClick={buttonClick}
        >
          Скачать
        </Button>
      </Card.Body>
    </div>
  );
};
const mapStateToProps = (state: any) => {
  return {
    layers: state.layers,
    users: state.users,
  };
};
const matchDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      setPushButton: pushButton,
      setTempLyrAdd: actionTempLyr,
      setMapZoom: actionZoom,
      setMapCenter: actionCenter,
      setCheckbox: actionCheckbox,
    },
    dispatch
  );
};

export default connect(mapStateToProps, matchDispatchToProps)(Temperature);
