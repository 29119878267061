const SET_TASKS_LIST = "SET_TASKS_LIST";

const defaultState = {
  tasks: []
};

export default function tasksReducer(state = defaultState, action: any) {
  switch (action.type) {
    case SET_TASKS_LIST:
      return {
        ...state,
        tasks: action.payload,
      };
    default:
      return state;
  }
}

export const setTaskList = (tasks: any) => ({ type: SET_TASKS_LIST, payload: tasks });
