import React from "react";
import {
  Button,
  Card,
} from "react-bootstrap";
import Table from 'react-bootstrap/Table';
import Badge from 'react-bootstrap/Badge';
import SpinnerForLoading from "../../../additional/Spinner/SpinnerForLoading";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { pushButton } from "./tasksActions/buttonPush";
import "bootstrap/dist/css/bootstrap.min.css";
import { useSelector, useDispatch } from "react-redux";
import { getAllTasks } from "../../../../actions/tasks";

const Basemap: React.FC = (props: any) => {

  const dispatch = useDispatch()

  const tasks = useSelector((state) => props.tasks);

  tasks.tasks.sort((a: any, b: any) => Date.parse(b["UpdatedAt"]) - Date.parse(a["UpdatedAt"]));

  const last_tasks = tasks.tasks.slice(0, 7);

  const hasTasks = tasks.tasks.length > 0;

  interface Task {
    ID: number,
    status: string
    downloadURL: string,
    service: string,
    CreatedAt: string,
  }

  return (
    <div className="basemap">
      <Card.Body>
      <Button
          size="sm"
          type="button"
          disabled={props.layers[4].downloadChecked}
          onClick={() => dispatch(getAllTasks())}
        >
          Обновить список задач
        </Button>
        <br/>
        <br/>
        
    <Table striped bordered hover size="sm">
      <thead>
        <tr>
          <th>ID</th>
          <th>Дата/Время</th>
          <th>Выгрузка</th>
          <th>Статус</th>
        </tr>
      </thead>
      <tbody>
      {hasTasks ? 
            last_tasks.map((t: Task) => {
                return (
        <tr>
          <td>{t.ID}</td>
          <td>{t.CreatedAt.split('T')[0] + ' ' + t.CreatedAt.split('T')[1]}</td>
          <td>
           <a href={t.downloadURL}>{t.service}</a>
          </td>
          <td>
            {t.status === 'finished' ?
            <Badge variant="success">Готово</Badge> : <SpinnerForLoading/>}
            </td>
        </tr>
                ) }) : null }
      </tbody>
    </Table>
        
      </Card.Body>
      {/*
        </Accordion.Collapse>
      </Card>
      */}
    </div>
  );
};
const mapStateToProps = (state: any) => {
  return {
    layers: state.layers,
    tasks: state.tasks,
    users: state.users
  };
};
const matchDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      setPushButton: pushButton,
    },
    dispatch
  );
};

export default connect(mapStateToProps, matchDispatchToProps)(Basemap);
