const SET_SEQ_DATES = "SET_SEQ_DATES";

const defaultState = {
    //seq_dates_json: {"001": {"png_names": "2024_05_14__19_00", "front_names": "14.05.2024 19:00"}, "002": {"png_names": "2024_05_14__20_00", "front_names": "14.05.2024 20:00"}, "003": {"png_names": "2024_05_14__21_00", "front_names": "14.05.2024 21:00"}},
    seq_dates_json: {},
  };

interface dataInterface {
    png_names: string,
    front_names: string
  }
  type datasOptions = {
    [key: string]: dataInterface;
  };

export default function meteoredReducer(state = defaultState, action: any) {
  switch (action.type) {
    case SET_SEQ_DATES:
      return {
        ...state,
        seq_dates_json: action.payload,
      };
    default:
      return state;
  }
}

export const setSeqDates = (seq_dates_json: datasOptions) => ({ type: SET_SEQ_DATES, payload: seq_dates_json });
