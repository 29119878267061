import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { Accordion, Card, Col, Row } from "react-bootstrap";
import BasemapCard from "../../Map/basemaps/basemap-card";
import { connect } from "react-redux";
import ZoomChange from "../../additional/zoom-change/zoom-change";
import HistoricalDateChange from "../../additional/historical-date-change/historical-date-change";

const Basemaps: React.FC = (props: any) => {
  const useHandleZoom =
    props.layers[0].style == "https://geo.emg24.ru/geoserver/baselayers/wms";
  const useHistorical =
    props.layers[0].style == "https://geo.emg24.ru/geoserver/historical/wms";
  return (
    <div className="basemap">
      {/*
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey="4">
          Базовая карта
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="4">
  */}
      <Card.Body>
        <ZoomChange />
        <HistoricalDateChange />
      </Card.Body>
      {/*
        </Accordion.Collapse>
      </Card>
*/}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    layers: state.layers,
  };
};

export default connect(mapStateToProps)(Basemaps);
