import { isConstructorDeclaration } from "typescript";
import { IGeoJSONFeatureCollection } from "../../common-types/geoserver";

export const getGeoserverGeojson = async (
  url: string
): Promise<IGeoJSONFeatureCollection> => {
  const response = await fetch(url);
  const data = await response.json();
  return data;
};
