import {
  Button,
  OverlayTrigger,
  Popover,
  Row,
  Col,
  Container,
  Card,
} from "react-bootstrap";
import { IconLegend } from "../../../icons/legend-icon";
import { connect } from "react-redux";
import "./legend.css";

const Legend: React.FC = (props: any) => {
  const title = (value: string) => {
    if (!props.layers[3].ratio) {
      if (value === "sick") {
        return "Число заболевших, тыс.чел";
      } else if (value === "died") {
        return "Число погибших, тыс.чел";
      } else if (value === "healed") {
        return "Число выздоровевших, тыс.чел";
      }
    } else {
      if (value === "sick") {
        return "Число заболевших на 100 тыс.чел";
      } else if (value === "died") {
        return "Число погибших на 100 тыс.чел";
      } else if (value === "healed") {
        return "Число выздоровевших на 100 тыс.чел";
      }
    }
  };
  const popover = (
    <Popover id="popover-basic" className="popoverW">
      <Card style={{ margin: "5px" }}>
        <h6>{title(props.layers[3].value)}</h6>
        <Container style={{ marginRight: "35px", marginBottom: "5px" }}>
          <Row>
            <Col style={{ backgroundColor: "#fce480" }}>
              {props.layers[3].scale[0] / 1000}
            </Col>
            <Col style={{ backgroundColor: "#feb934" }}>
              {props.layers[3].scale[1] / 1000}
            </Col>
            <Col style={{ backgroundColor: "#fe8034" }}>
              {props.layers[3].scale[2] / 1000}
            </Col>
            <Col style={{ backgroundColor: "#fe3434" }}>
              {props.layers[3].scale[3] / 1000}
            </Col>
            <Col style={{ backgroundColor: "#cc0022" }}></Col>
          </Row>
        </Container>
      </Card>
    </Popover>
  );
  const useLegend =
    props.layers[3].lyr_render == true && props.layers[3].lyr_visible == true;
  return (
    <OverlayTrigger trigger="click" placement="left" overlay={popover}>
      {useLegend ? (
        <Button className="legend" variant="light" size="sm">
          <IconLegend />
        </Button>
      ) : (
        <Button className="legend" variant="light" size="sm" disabled>
          <IconLegend />
        </Button>
      )}
    </OverlayTrigger>
  );
};

const mapStateToProps = (state: any) => {
  return {
    layers: state.layers,
  };
};

export default connect(mapStateToProps)(Legend);
