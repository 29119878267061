import { FeatureGroup, GeoJSON, Popup } from "react-leaflet";
import { IGeoJSONFeature } from "../../common-types/geoserver";
import { PopupTable } from "./popup-table";

interface IGeoserverLyr {
  data?: any;
}

export const FeatureGroupGeoserver = (props: IGeoserverLyr) => {
  const lyrStyle = (f: IGeoJSONFeature) => {
    return {
      color: "white",
      fillOpacity: 0.75,
      fillColor: f.properties.COLORS,
    };
  };

  return (
    <FeatureGroup>
      {props.data.features.map((f: IGeoJSONFeature) => {
        return (
          <GeoJSON key={f.properties.objectid} data={f} style={lyrStyle(f)}>
            <PopupTable properties={f.properties} />
          </GeoJSON>
        );
      })}
    </FeatureGroup>
  );
};
