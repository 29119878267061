import React from "react";
import { Map, TileLayer, FeatureGroup, WMSTileLayer } from "react-leaflet";
import { Card } from "react-bootstrap";
import { EditControl } from "react-leaflet-draw";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { currentbounds } from "./actions/index";
import { actionShapes } from "./actions/actionShapes";
import { actionDeleteShapes } from "./actions/actionDeleteShapes";
import { actionPolygonsArr } from "./actions/actionPolygonsArr";
import { actionZoom } from "../Toolbox/Cards/Radar/radarActions/changeZoom";
import { actionLevel } from "../Toolbox/Cards/Basemap/basemapActions/levelChoose";
import RainviewerTiles from "../Toolbox/Cards/Radar/rainviewerTiles";
import GeojsonLayer from "../Toolbox/Cards/Basemap/Layers";
import { GeoserverLyr } from "../../modules/layer-map/geoserver-lyr";
import L from 'leaflet'
import "./Map.css";

const LeafletMap: React.FC = (props: any) => {
  L.Icon.Default.imagePath='img/'
  const purpleOptions = { color: "purple" };

  const setZoom = (e: any) => {
    if (
      e.zoom != props.layers[0].zoom &&
      props.layers[0].zoom_change === false &&
      props.layers[0].year >= 2000
    ) {
      props.zoomChange(e.zoom);
      if (props.layers[0].zoom <= 5 && props.layers[0].zoom_change === false) {
        props.setLevel("M10");
      } else if (props.layers[0].zoom > 5 && props.layers[0].zoom <= 7) {
        props.setLevel("M05");
      } else if (props.layers[0].zoom > 7 && props.layers[0].zoom <= 10) {
        props.setLevel("M01");
      }
    }
  };

  const _onCreate = (e: any) => {
    let bounds = e.layer._bounds;
    let currentBounds = [
      bounds._southWest.lat,
      bounds._southWest.lng,
      bounds._northEast.lat,
      bounds._northEast.lng,
    ];
    props.addBounds(currentBounds);

    let polygonsDict = props.layers[0].polygonsArr;
    let currentId = e.layer._leaflet_id;
    polygonsDict[currentId] = e.layer._latlngs[0];
    props.addPolygonsArr(polygonsDict);

    let allShapes = [];
    let allIds = Object.keys(polygonsDict);
    for (let a = 0; a < allIds.length; a++) {
      let currentShape = [];
      let shape = polygonsDict[allIds[Number(a)]];
      for (let i = 0; i < shape.length; i++) {
        let coord = [shape[i].lat, shape[i].lng];
        currentShape.push(coord);
      }
      allShapes.push(currentShape);
    }
    props.addShapes(allShapes);
    /*
        let shapes = e.layer._latlngs[0];
        let currentShape = [];
        for (let i = 0; i < shapes.length; i++) {
          let coord = [shapes[i].lat, shapes[i].lng];
          currentShape.push(coord);
        }
        let allShapes = props.layers[0].polygons;
        allShapes.push(currentShape);
        props.addShapes(allShapes);
        */
  };
  const _onDelete = (e: any) => {
    let polygonsDict = props.layers[0].polygonsArr;
    let deletedIds = Object.keys(e.layers._layers);
    console.log(deletedIds);
    for (let i = 0; i < deletedIds.length; i++) {
      let indValue = deletedIds[i];
      delete polygonsDict[indValue];
      props.addPolygonsArr(polygonsDict);
    }
    let allShapes = [];
    let allIds = Object.keys(polygonsDict);
    for (let a = 0; a < allIds.length; a++) {
      let currentShape = [];
      let shape = polygonsDict[allIds[Number(a)]];
      for (let i = 0; i < shape.length; i++) {
        let coord = [shape[i].lat, shape[i].lng];
        currentShape.push(coord);
      }
      allShapes.push(currentShape);
    }
    props.addShapes(allShapes);
  };
  const _onEdit = (e: any) => {
    let polygonsDict = props.layers[0].polygonsArr;
    let currentId = Object.keys(e.layers._layers)[0];
    if (Object.keys(e.layers._layers).length <= 0) {
      console.log("null");
    } else {
      console.log("not null");
      polygonsDict[currentId] = e.layers._layers[currentId]._latlngs[0];
      props.addPolygonsArr(polygonsDict);

      let allShapes = [];
      let allIds = Object.keys(polygonsDict);
      for (let a = 0; a < allIds.length; a++) {
        let currentShape = [];
        let shape = polygonsDict[allIds[Number(a)]];
        for (let i = 0; i < shape.length; i++) {
          let coord = [shape[i].lat, shape[i].lng];
          currentShape.push(coord);
        }
        allShapes.push(currentShape);
      }
      props.addShapes(allShapes);
    }
  };
  const additionalLyr = {
    M500: "https://geo.emg24.ru/geoserver/additionallayers/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=additionallayers%3Am500&maxFeatures=50&outputFormat=application%2Fjson",
    M200: "https://geo.emg24.ru/geoserver/additionallayers/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=additionallayers%3Am200&maxFeatures=50&outputFormat=application%2Fjson",
    temperature:
      "https://geo.emg24.ru/geoserver/publiclayers/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=publiclayers%3Atemp_atmo&maxFeatures=50&outputFormat=application%2Fjson",
    vybory_lyr:
      "https://geo.emg24.ru/geoserver/publiclayers/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=publiclayers%3Avybory_lyr_2021&maxFeatures=88&outputFormat=application%2Fjson",
    corona:
      "https://geo.emg24.ru/geoserver/publiclayers/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=publiclayers%3Acorona_rus_reg&maxFeatures=90&outputFormat=application%2Fjson",
    ugms: "https://geo.emg24.ru/geoserver/publiclayers/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=publiclayers%3Augms&maxFeatures=120&outputFormat=application%2Fjson",
    gismeteo:
      "https://geo.emg24.ru/geoserver/publiclayers/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=publiclayers%3Aprecipitation_atmo&maxFeatures=120&outputFormat=application%2Fjson",
    firms:
      "https://geo.emg24.ru/geoserver/publiclayers/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=publiclayers%3Afirms_points&maxFeatures=950&outputFormat=application%2Fjson",
    geocoding:
      "https://geo.emg24.ru/geoserver/publiclayers/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=publiclayers%3Ageocoding&maxFeatures=50&outputFormat=application%2Fjson",
  };
  //const baselayers = { M10: "baselayers:group_m11", M05: "", M01: "" };
  return (
    <Map
      zoomControl={true}
      center={props.layers[0].center}
      zoom={props.layers[0].zoom}
      minZoom={2}
      onViewportChange={setZoom}
    >
      {props.layers.map((l: any) => {
        const useWMS = props.layers[0].basemaps;
        const useHistorical =
          props.layers[0].style ===
          "https://geo.emg24.ru/geoserver/historical/wms";
        {
          if (useHistorical) {
            let level_for_historical = props.layers[4].dataset;
            let historical_group =
              "historical:historical_" +
              level_for_historical.replace("y", "").replace("_cntpol", "");
            return (
              <WMSTileLayer
                layers={historical_group}
                url={props.layers[0].style}
                transparent={true}
                format={"image/png"}
              />
            );
          }
        }
        {
          if (useWMS) {
            return (
              <TileLayer url={props.layers[0].style} opacity={1}></TileLayer>
            );
          } else {
            if (props.layers[0].zoom_change === false) {
              let basemap_group =
                "baselayers:" +
                props.layers[4].dataset +
                "_" +
                props.layers[4].level;
              return (
                <WMSTileLayer
                  layers={basemap_group}
                  url={props.layers[0].style}
                  transparent={true}
                  format={"image/png"}
                />
              );
            } else if (props.layers[0].zoom_change === true) {
              console.log("zoom_change", props.layers[0].zoom_change);
              console.log("zoom_change", props.layers[4]);
              if (props.layers[4].level === "M10") {
                return (
                  <WMSTileLayer
                    layers={"baselayers:group_m11"}
                    url={props.layers[0].style}
                    transparent={true}
                    format={"image/png"}
                  />
                );
              } else if (props.layers[4].level === "M05") {
                return (
                  <WMSTileLayer
                    layers={"baselayers:group_m05"}
                    url={props.layers[0].style}
                    transparent={true}
                    format={"image/png"}
                  />
                );
              } else if (props.layers[4].level === "M01") {
                return (
                  <WMSTileLayer
                    layers={"baselayers:group_m01"}
                    url={props.layers[0].style}
                    transparent={true}
                    format={"image/png"}
                    tiled={true}
                  />
                );
              } else if (props.layers[4].level === "M500") {
                return (
                  <WMSTileLayer
                    layers={"baselayers:group_m500"}
                    url={props.layers[0].style}
                    transparent={true}
                    format={"image/png"}
                    tiled={true}
                  />
                );
              }
            }
          }
        }
      })}

      {props.layers.map((l: any) => {
        if (l.rainviewer === true) {
          return (
            <RainviewerTiles url="https://api.rainviewer.com/public/weather-maps.json" />
          );
        }
      })}

      {props.layers.map((l: any) => {
        if (l.lyr_render === true && l.lyr_visible === true) {
          switch (l.id) {
            case "corona":
              return <GeoserverLyr key={l.id} url={additionalLyr["corona"]} />;
              break;
            case "temperature":
              return (
                <GeoserverLyr key={l.id} url={additionalLyr["temperature"]} />
              );
              break;
            case "hydro":
              return <GeoserverLyr key={l.id} url={additionalLyr["ugms"]} />;
              break;
            case "gismeteo":
              return (
                <GeoserverLyr key={l.id} url={additionalLyr["gismeteo"]} />
              );
              break;
            case "firms":
              return <GeoserverLyr key={l.id} url={additionalLyr["firms"]} />;
              break;
            case "geocoding":
                return <GeoserverLyr key={l.id} url={additionalLyr["geocoding"]} />;
                break;
          }
        }
      })}

      {props.layers.map((l: any) => {
        let level: string = l.level;
        if (level === "M500" || level === "M200") {
          return <GeojsonLayer key={level} url={additionalLyr[level]} />;
        }
      })}
      <FeatureGroup>
        <EditControl
          position="bottomleft"
          onCreated={_onCreate}
          onDeleted={_onDelete}
          onEdited={_onEdit}
          draw={{
            rectangle: true,
            polyline: false,
            marker: false,
            circle: false,
            circlemarker: false,
          }}
        />
      </FeatureGroup>
    </Map>
  );
};
const mapStateToProps = (state: any) => {
  return {
    layers: state.layers,
  };
};

const matchDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      addBounds: currentbounds,
      addShapes: actionShapes,
      deleteShapes: actionDeleteShapes,
      addPolygonsArr: actionPolygonsArr,
      zoomChange: actionZoom,
      setLevel: actionLevel,
    },
    dispatch
  );
};

export default connect(mapStateToProps, matchDispatchToProps)(LeafletMap);
