import axios from "axios";
import { setTaskList } from "../reducers/tasks";

export const getAllTasks = () => {
  const token = localStorage.getItem("token");
  return async (dispatch: any) => {
    try {
      const response = await axios.get(`https://geo.emg24.ru/api/tasks`,
        {
          headers: {
            'Token': token
          }
      });
      dispatch(setTaskList(response.data));
    } catch (e) {
      alert(e);
    }
  };
};
