import axios from "axios";
import { setSeqDates } from "../reducers/meteored";

export const getSeqDatesMeteored = () => {
  const token = localStorage.getItem("token");
  return async (dispatch: any) => {
    try {
      const response = await axios.post(`/api/meteored_data`,  {"value": "temperature"},
        {
          headers: {
            'Token': token
          }
      });
      dispatch(setSeqDates(response.data));
    } catch (e) {
      console.log(e);
    }
  };
};
