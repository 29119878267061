import { useQuery, UseQueryResult } from "react-query";
import { IGeoJSONFeature } from "../../common-types/geoserver";
import { getGeoserverGeojson } from "./api";

interface IGeoJSONFeatures {
  [key: string]: IGeoJSONFeature[];
}

export const useGetGeoserverGeojson = (
  url: string
): UseQueryResult<IGeoJSONFeatures> => {
  return useQuery(url, () => getGeoserverGeojson(url).then());
};
