import { useGetGeoserverGeojson } from "../../api/geoserver-geojson/hook";
import { FeatureGroupGeoserver } from "./feature-group";

interface IGeoserverLyr {
  url: string;
}

export const GeoserverLyr = (props: IGeoserverLyr) => {
  const { isSuccess, data } = useGetGeoserverGeojson(props.url);

  return <>{isSuccess ? <FeatureGroupGeoserver data={data} /> : null}</>;
};
