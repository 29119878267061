import React, { useState } from "react";
import { Button, Accordion, Card, Form, Col } from "react-bootstrap";
import SpinnerForLoading from "../../../additional/Spinner/SpinnerForLoading";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { pushButton } from "./radarActions/buttonPush";
import { actionArea } from "./radarActions/areaChoose";
import { actionCellsize } from "./radarActions/cellsizeChoose";
import { actionHour } from "./radarActions/hourChoose";
import { actionMinute } from "./radarActions/minuteChoose";
import { actionPeriod } from "./radarActions/periodChoose";
import { actionCheckbox } from "./radarActions/checkboxChange";
import { actionZoom } from "./radarActions/changeZoom";
import { actionCenter } from "./radarActions/changeCenter";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";

const Radar: React.FC = (props: any) => {
  const [checked, setChecked] = useState(props.layers[1].downloadChecked);
  const buttonClick = (e: any) => {
    props.setPushButton(true);
    console.log(props.layers[1]);
    console.log(props.layers[0].bounds);

    if (
      props.layers[0].bounds.length === 0 &&
      props.layers[1].area === "bbox"
    ) {
      alert(
        "Обозначьте территорию для выгрузки при помощи инструмента Draw a rectangle! (левый нижний угол карты)"
      );
      props.setPushButton(false);
      return;
    }

    let jbody = props.layers[1];
    jbody.bbox = props.layers[0].bounds;

    axios
      .post("/api/radar", jbody)
      .then((r) => {
        alert(
          "Все материалы лежат по адресу:\nW:\\Big_Project\\__MAPS\\!Py\\_RADAR_SEQ"
        );
        props.setPushButton(false);
      })
      .catch((error) => {
        props.setPushButton(false);
        alert(`Radar error! ${error.response.data.error}`);
      });
  };
  const areaChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    switch (e.target.value) {
      case "Европейская часть России":
        console.log("etr");
        props.setArea("etr");
        props.setMapZoom(3);
        props.setMapCenter([62.01, 47.01]);
        if (props.layers[1].downloadChecked === true) {
          props.setPushButton(false);
        }
        break;
      case "Европейская часть России (центр)":
        console.log("etr_center");
        props.setArea("etr_center");
        props.setMapZoom(3);
        props.setMapCenter([52.354939, 41.997426]);
        if (props.layers[1].downloadChecked === true) {
          props.setPushButton(false);
        }
        break;
      case "Кавказ":
        props.setArea("caucase");
        props.setMapZoom(5);
        props.setMapCenter([44.93, 42.8]);
        if (props.layers[1].downloadChecked === true) {
          props.setPushButton(false);
        }
        break;
      case "Москва и Московская область":
        props.setArea("mo");
        props.setMapZoom(5);
        props.setMapCenter([55.5, 37.8]);
        if (props.layers[1].downloadChecked === true) {
          props.setPushButton(false);
        }
        break;
      case "Дальний Восток":
        props.setArea("dv");
        props.setMapZoom(5);
        props.setMapCenter([55.5, 37.8]);
        if (props.layers[1].downloadChecked === true) {
          props.setPushButton(false);
        }
        break;
      case "из моего охвата":
        props.setArea("bbox");
        if (props.layers[1].downloadChecked === true) {
          props.setPushButton(false);
        }
    }
    console.log(props.layers[1]);
  };

  const cellsizeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.setCellsize(Number(e.target.value));
    if (props.layers[1].downloadChecked === true) {
      props.setPushButton(false);
    }
    console.log(props.layers[1]);
  };
  const hourChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.setHour(Number(e.target.value));
    if (props.layers[1].downloadChecked === true) {
      props.setPushButton(false);
    }
    console.log(props.layers[1]);
  };
  const minuteChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.setMinute(Number(e.target.value));
    if (props.layers[1].downloadChecked === true) {
      props.setPushButton(false);
    }
    console.log(props.layers[1]);
  };
  const periodChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.setPeriod(Number(e.target.value));
    if (props.layers[1].downloadChecked === true) {
      props.setPushButton(false);
    }
    console.log(props.layers[1]);
  };
  const checkboxCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    switch (props.layers[1].rainviewer) {
      case false:
        switch (props.layers[1].area) {
          case "etr":
            props.setMapZoom(3);
            props.setMapCenter([62.01, 47.01]);
            break;
          case "etr_center":
            props.setMapZoom(3);
            props.setMapCenter([52.354939, 41.997426]);
            break;
          case "caucase":
            props.setMapZoom(5);
            props.setMapCenter([44.93, 42.8]);
            break;
          case "mo":
            props.setMapZoom(5);
            props.setMapCenter([55.5, 37.8]);
            break;
        }
        props.setCheckbox(true);
        break;
      case true:
        props.setCheckbox(false);
    }
  };

  return (
    <div className="radar">
      {/*<Card>
                <Accordion.Toggle as={Card.Header} eventKey="0">
                Радар
                {props.layers.map( (l:any) => {
                    let id:string = l.id;
                    let download:boolean = l.downloadChecked
                    if (id === "radar" && download === true) {return ( <SpinnerForLoading key={id}/> )}              
                })}
                </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">*/}
      <Card.Body>
        <Form.Group controlId="formBasicCheckboxRadar">
          <Form.Check
            type="checkbox"
            label="показать радар и охват на карте"
            onChange={checkboxCheck}
          />
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlSelect1">
          <Form.Label>Выберите территорию</Form.Label>
          <Form.Control size="sm" as="select" onChange={areaChange}>
            <option>Европейская часть России</option>
            <option>Европейская часть России (центр)</option>
            <option>Кавказ</option>
            <option>Москва и Московская область</option>
            <option>Дальний Восток</option>
            <option>из моего охвата</option>
          </Form.Control>
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlSelect2">
          <Form.Label>Выберите размер ячейки</Form.Label>
          <Form.Control size="sm" as="select" onChange={cellsizeChange}>
            <option>20</option>
            <option>10</option>
            <option>5</option>
          </Form.Control>
        </Form.Group>
        <Form.Row>
          <Form.Group as={Col} controlId="formGridEmail">
            <Form.Label>Часы</Form.Label>
            <Form.Control
              size="sm"
              as="select"
              defaultValue="09"
              onChange={hourChange}
            >
              <option>00</option>
              <option>01</option>
              <option>02</option>
              <option>03</option>
              <option>04</option>
              <option>05</option>
              <option>06</option>
              <option>07</option>
              <option>08</option>
              <option>09</option>
              <option>10</option>
              <option>11</option>
              <option>12</option>
              <option>13</option>
              <option>14</option>
              <option>15</option>
              <option>16</option>
              <option>17</option>
              <option>18</option>
              <option>19</option>
              <option>20</option>
              <option>21</option>
              <option>22</option>
              <option>23</option>
            </Form.Control>
          </Form.Group>

          <Form.Group as={Col} controlId="formGridPassword">
            <Form.Label>Минуты</Form.Label>
            <Form.Control
              size="sm"
              as="select"
              defaultValue="00"
              onChange={minuteChange}
            >
              <option>00</option>
              <option>10</option>
              <option>20</option>
              <option>30</option>
              <option>40</option>
              <option>50</option>
            </Form.Control>
          </Form.Group>
        </Form.Row>
        <Form.Group controlId="exampleForm.ControlSelect2">
          <Form.Label>Выберите период (в часах)</Form.Label>
          <Form.Control size="sm" as="select" onChange={periodChange}>
            <option>1</option>
            <option>2</option>
            <option>3</option>
          </Form.Control>
        </Form.Group>
        <Button
          size="sm"
          type="button"
          disabled={props.layers[1].downloadChecked}
          onClick={buttonClick}
        >
          Скачать
        </Button>
      </Card.Body>
      {/*</Accordion.Collapse>
            </Card>*/}
    </div>
  );
};
const mapStateToProps = (state: any) => {
  return {
    layers: state.layers,
  };
};
const matchDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      setPushButton: pushButton,
      setArea: actionArea,
      setCellsize: actionCellsize,
      setHour: actionHour,
      setMinute: actionMinute,
      setPeriod: actionPeriod,
      setCheckbox: actionCheckbox,
      setMapZoom: actionZoom,
      setMapCenter: actionCenter,
    },
    dispatch
  );
};

export default connect(mapStateToProps, matchDispatchToProps)(Radar);
