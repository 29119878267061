import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import {useDispatch} from "react-redux";
import {login} from "../../../actions/user";
import { useSelector } from "react-redux";
import {logout} from "../../../reducers/users";

function TextLinkExample() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [email, setEmail] = useState(""), onInputEmail = ({target:{value}}) => setEmail(value)
  const [password, setPassword] = useState(""), onInputPass = ({target:{value}}) => setPassword(value)
  const dispatch = useDispatch()

  const isAuth = useSelector((state) => state.users.isAuth);

  return (
    <Navbar style={{ backgroundColor: "#1E54B3" }} variant="dark">
      <Container>
        <Navbar.Brand>
          <img
            alt=""
            src="https://raw.githubusercontent.com/ValeriaBelousova/temp/master/icon/EMG.svg"
            width="30"
            height="30"
            className="d-inline-block align-top"
          />{" "}
          Геосервер
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          {!isAuth ? (<Button variant="primary" onClick={handleShow}>
            Вход
          </Button>): (<Button variant="primary" onClick={() => dispatch(logout())}>
            Выход
          </Button>)}
          {!isAuth ? (
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Вход в аккаунт</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group
                  className="mb-3"
                  controlId="loginForm.email"
                >
                  <Form.Label>Логин/Email</Form.Label>
                  <Form.Control
                    type="email"
                    value={email} 
                    onChange={onInputEmail}
                    placeholder="name@example.com"
                    autoFocus
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="loginForm.pass"
                >
                  <Form.Label>Пароль</Form.Label>
                  <Form.Control 
                    type="password" 
                    value={password} 
                    onChange={onInputPass}
                    autoFocus />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={() => dispatch(login(email, password))}>
                Войти
              </Button>
            </Modal.Footer>
          </Modal>) : null }
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default TextLinkExample;
