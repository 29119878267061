import React from 'react';
import {Button, Accordion, Card, Form} from 'react-bootstrap';
import SpinnerForLoading from '../../../additional/Spinner/SpinnerForLoading';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {pushButton} from "./satelliteAction/buttonPush";
import { actionOMlyrAdd } from "./satelliteAction/actionOMlyrAdd";
import axios from 'axios';
import { useSelector } from "react-redux";
import 'bootstrap/dist/css/bootstrap.min.css';

const Satellite: React.FC = (props: any) => {
    let filesToUpload: File[];

    const users = useSelector((state) => props.users);

    const changeHandler = (event: any) => {
        filesToUpload = event.target.files;
        console.log(props.layers[9]);
    }
    const buttonClick = (e: any) => {
        props.setPushButton(true);
        console.log(props.layers[9]);

        if (filesToUpload == null) {
            alert("Необходимо сначала выбрать файлы");
            props.setPushButton(false);
            return
        }
        const formData = new FormData();

        for (let x = 0; x < filesToUpload.length; x++) {
            formData.append('file', filesToUpload[x], filesToUpload[x].name);
        }

        const token = users.currentUser.token;
        const headers = {
            token: token,
            };
        axios.post("/api/satellite", formData, {
            headers: headers,
          }).then(r => {
                axios.get(r.data.url, {responseType: 'blob'}).then(res => {
                    const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = downloadUrl;

                    const urlElements = r.data.url.split('/')
                    const fileName = decodeURIComponent(urlElements[urlElements.length - 1])

                    link.setAttribute('download', fileName);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                    props.setPushButton(false);
                }).catch(error => {
                    props.setPushButton(false);
                    alert(`Output download error! ${error}`)
                })
            }
        ).catch(error => {
            props.setPushButton(false);
            alert(`Satellite error!`)
        });
    }

    const setOMLyr = (e:any) => {
        var om_layers = props.layers[9].om_layers;
        if ( om_layers.indexOf( e.target.id ) === -1  ) {
            om_layers.push(e.target.id);
            props.setOMLyr(om_layers);
        }
        console.log(props.layers[9]);
    }

    return (
        <div className='satellite'>
            {/*<Card>
                <Accordion.Toggle as={Card.Header} eventKey="8">
                    Спутник для Overmap
                {props.layers.map( (l:any) => {
                    let id:string = l.id;
                    let download:boolean = l.downloadChecked
                    if (id === "satellite" && download === true) {return ( <SpinnerForLoading key={id}/> )}              
                })}
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="8">
            */}
                    <Card.Body>
                        <form className="form-group">
                            <label htmlFor="formcontrolFileImages">Выберите файлы для загрузки</label>
                            <input multiple
                                   id="formcontrolFileImages"
                                   className="form-control-file"
                                   type="file"
                                   name="file"
                                   accept=".png, .jpg, .jpeg, .dat, .prj, .pgw, .jdw, .tab, .map, .kml"
                                   onChange={changeHandler}/>
                        </form>
                        <label htmlFor="formcontrolAdditionalLyr">Добавить в выгрузку</label>
                        <Form.Group controlId="om_roads">
                            <Form.Check type="checkbox" label="слой дорог" onChange={setOMLyr}/>
                        </Form.Group>
                        <Form.Group controlId="om_builds">
                            <Form.Check type="checkbox" label="слой зданий" onChange={setOMLyr}/>
                        </Form.Group>
                        <Button
                            size="sm"
                            type="button"
                            variant="light"
                            href="https://docs.google.com/document/d/1x4iMNvFq99jnjMOchIldErMkVhflhKuNcA2rlIno8Yg/edit?usp=sharing"
                            target="_blank">
                            Инструкция
                        </Button>
                        <Button
                            size="sm"
                            type="button"
                            disabled={props.layers[9].downloadChecked}
                            onClick={buttonClick}>
                            Скачать
                        </Button>
                        
                    </Card.Body>
                {/*</Accordion.Collapse>
            </Card>*/}
        </div>
    );
}
const mapStateToProps = (state: any) => {
    return {
        layers: state.layers,
        users: state.users,
    };
};
const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({
        setPushButton: pushButton,
        setOMLyr: actionOMlyrAdd,
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Satellite);
