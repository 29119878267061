interface dataInterface {
    png_names: string,
    front_names: string
  }
  type datasOptions = {
    [key: string]: dataInterface;
  };
export const actionDatas = (seq_dates_json: datasOptions, datas: string) => {
    return {
        type: "METEORED_DATAS_CHOOSE",
        payload: { seq_dates_json: seq_dates_json, datas: datas }
    }
}