import React, { useState } from "react";
import {
  Button,
  Accordion,
  Card,
  Form,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { pushButton } from "./basemapActions/buttonPush";
import { actionStyle } from "./basemapActions/styleChoose";
import { actionExportSize } from "./basemapActions/actionExportSize";
import { actionSetPath, actionSetFolderName } from "./basemapActions";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import { useSelector } from "react-redux";

const Basemap: React.FC = (props: any) => {

  const users = useSelector((state) => props.users);

  const buttonClick = (e: any) => {
    props.setPushButton(true);
    var jbody = props.layers[4];
    jbody.bounds = props.layers[0].bounds; // adding bounds to layer obj

    if (jbody.bounds.length < 4) {
      alert("Ошибка. Для выгрузки базовой карты нужно выбрать регион.");
      props.setPushButton(false);
      return;
    }
    
    const token = users.currentUser.token;
    const headers = {
      token: token,
    };

    axios
      .post("/api/basemap", jbody, {
        headers: headers,
      })
      .then((r) => {
        alert(
          `${r.data.downloadURL}`
        );
        props.setPushButton(false);
      })
      .catch((error) => {
        console.log('error', error);
        props.setPushButton(false);
        alert(`Basemap error!`);
      });
  };

  const styleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    switch (e.target.value) {
      case "РИК (Новости/Экономика)":
        props.setStyle("rikNews");
        if (props.layers[4].downloadChecked === true) {
          props.setPushButton(false);
        }
        break;
      case "Москва (все программы)":
        props.setStyle("mskNews");
        if (props.layers[4].downloadChecked === true) {
          props.setPushButton(false);
        }
    }
  };

  const maxPXsize = (e: React.ChangeEvent<HTMLInputElement>) => {
    let size = Number(e.target.value);
    props.setExportSize(size);
    if (props.layers[4].downloadChecked === true) {
      props.setPushButton(false);
    }
    console.log(props.layers[4]);
  };

  const addFolderPath = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log(e.target.value);
    let name = e.target.value.toString();
    props.setPath(name);
    if (props.layers[4].downloadChecked === true) {
      props.setPushButton(false);
    }
    console.log(props.layers[4]);
  };

  const addFolderName = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log(e.target.value);
    let name = e.target.value.toString();
    props.setFolderName(name);
    if (props.layers[4].downloadChecked === true) {
      props.setPushButton(false);
    }
    console.log(props.layers[4]);
  };

  return (
    <div className="basemap">
      <Card.Body>
        <h6>Настройки экспорта</h6>
        <Form.Group controlId="exampleForm.ControlSelect2">
          <Form.Label>Выберите оформление</Form.Label>
          <Form.Control size="sm" as="select" onChange={styleChange}>
            <option>РИК (Новости/Экономика)</option>
            <option>Москва (все программы)</option>
          </Form.Control>
        </Form.Group>

        <InputGroup size="sm" className="mb-3">
          <InputGroup.Prepend>
            <InputGroup.Text id="inputGroup-sizing-sm">
              Размер по ширине, px
            </InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            aria-label="Small"
            aria-describedby="inputGroup-sizing-sm"
            placeholder={"2000"}
            onChange={maxPXsize}
          />
        </InputGroup>

        <h6>Настройки сохранения</h6>
        <InputGroup size="sm" className="mb-3">
          <InputGroup.Prepend>
            <InputGroup.Text id="inputGroup-sizing-sm">
              Путь к папке W:/Big_Project/
            </InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            aria-label="Small"
            aria-describedby="inputGroup-sizing-sm"
            placeholder={"__MAPS/!Py/_BASEMAP_EXP"}
            onChange={addFolderPath}
          />
        </InputGroup>
        <InputGroup size="sm" className="mb-3">
          <InputGroup.Prepend>
            <InputGroup.Text id="inputGroup-sizing-sm">
              Название папки
            </InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            aria-label="Small"
            aria-describedby="inputGroup-sizing-sm"
            placeholder={"Basemap"}
            onChange={addFolderName}
          />
        </InputGroup>
        <Button
          size="sm"
          type="button"
          disabled={props.layers[4].downloadChecked}
          onClick={buttonClick}
        >
          Скачать
        </Button>
      </Card.Body>
      {/*
        </Accordion.Collapse>
      </Card>
      */}
    </div>
  );
};
const mapStateToProps = (state: any) => {
  return {
    layers: state.layers,
    users: state.users,
  };
};
const matchDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      setPushButton: pushButton,
      setStyle: actionStyle,
      setExportSize: actionExportSize,
      setPath: actionSetPath,
      setFolderName: actionSetFolderName,
    },
    dispatch
  );
};

export default connect(mapStateToProps, matchDispatchToProps)(Basemap);
