import React, { useState } from 'react';
import { Button, Accordion, Card, Form, InputGroup, FormControl } from 'react-bootstrap';
import SpinnerForLoading from '../../../additional/Spinner/SpinnerForLoading';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { pushButton } from "./coronaActions/buttonPush";
import { actionArea } from "./coronaActions/areaChoose";
import { actionStyle } from "./coronaActions/styleChoose";
import { actionValue } from "./coronaActions/valueChoose";
import { actionScale } from "./coronaActions/scaleChoose";
import { actionCheckbox } from "./coronaActions/checkboxChange";
import { actionLayersTocRender } from "../../../../modules/layers-toc/actionLayerRender";
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from "axios";
import { useSelector } from "react-redux";

const Corona:React.FC = (props:any) => {
    const [checked, setChecked] = useState(props.layers[3].downloadChecked);

    const users = useSelector((state) => props.users);


    const buttonClick = (e:any) => {
        props.setPushButton(true);
        console.log(props.layers[3])

        const token = users.currentUser.token;
        const headers = {
            token: token,
        };

        axios.post("/api/corona", props.layers[3], {
            headers: headers,
          }).then(r => {
                alert('Готово! Все материалы во вкладке Задачи')
                props.setPushButton(false);
                props.setLyr("corona", true);
            }
        ).catch(error => {
            props.setPushButton(false);
            props.setLyr("corona", false);
            alert(`Corona error!`)
        });
    }
    const areaChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        switch (e.target.value) {
            case 'Россия':
                //console.log('etr')
                props.setArea("rus");
                if (props.layers[3].downloadChecked===true) {props.setPushButton(false)}
                break;
            case 'Мир':
                props.setArea("world");
                if (props.layers[3].downloadChecked===true) {props.setPushButton(false)}
        }
        console.log(props.layers)
    }
    const styleChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        switch (e.target.value) {
            case 'РИК':
                //console.log('etr')
                props.setStyle("rik");
                if (props.layers[3].downloadChecked===true) {props.setPushButton(false)}
                break;
            case 'Метро':
                //console.log('etr')
                props.setStyle("metro");
                if (props.layers[3].downloadChecked===true) {props.setPushButton(false)}
                break;
            case 'Москва':
                props.setStyle("msk");
                if (props.layers[3].downloadChecked===true) {props.setPushButton(false)}
        }
        console.log(props.layers)
    }
    const valueChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        switch (e.target.value) {
            case 'Заражения':
                //console.log('etr')
                props.setValue("sick");
                switch (props.layers[3].ratio) {
                    case false:
                        props.setScale([5000, 10000, 20000, 40000]);
                        break;
                    case true:
                        props.setScale([1500, 3000, 4500, 6000]);
                }
                if (props.layers[3].downloadChecked===true) {props.setPushButton(false)}
                break;
            case 'Смерти':
                //console.log('etr')
                props.setValue("died");
                switch (props.layers[3].ratio) {
                    case false:
                        props.setScale([500, 1000, 1500, 3000]);;
                        break;
                    case true:
                        props.setScale([10, 25, 50, 100]);
                }
                if (props.layers[3].downloadChecked===true) {props.setPushButton(false)}
                break;
            case 'Выздоровления':
                props.setValue("healed");
                switch (props.layers[3].ratio) {
                    case false:
                        props.setScale([15000, 30000, 60000, 100000]);;
                        break;
                    case true:
                        props.setScale([1000, 3000, 5000, 10000]);
                }
                if (props.layers[3].downloadChecked===true) {props.setPushButton(false)}
        }
        console.log(props.layers)
    }
    const checkboxCheck = (e:React.ChangeEvent<HTMLInputElement>) => {
        switch (props.layers[3].ratio) {
            case false:
                props.setCheckbox(true);
                switch (props.layers[3].value) {
                    case 'sick':
                        props.setScale([1500, 3000, 4500, 6000]);
                        break;
                    case 'died':
                        props.setScale([10, 25, 50, 100]);
                        break;
                    case 'healed':
                        props.setScale([1000, 3000, 5000, 10000]);
                }
                if (props.layers[3].downloadChecked===true) {props.setPushButton(false)};
                break;
            case true:
                props.setCheckbox(false);
                switch (props.layers[3].value) {
                    case 'sick':
                        props.setScale([5000, 10000, 20000, 40000]);
                        break;
                    case 'died':
                        props.setScale([500, 1000, 1500, 3000]);
                        break;
                    case 'healed':
                        props.setScale([15000, 30000, 60000, 100000]);
                }
                if (props.layers[3].downloadChecked===true) {props.setPushButton(false)};
        }
    }
    const scaleChange1 = (e:React.ChangeEvent<HTMLInputElement>) => {
        var scale = props.layers[3].scale;
        scale[0] = Number(e.target.value);
        props.setScale(scale);
        if (props.layers[3].downloadChecked===true) {props.setPushButton(false)};
    }
    const scaleChange2 = (e:React.ChangeEvent<HTMLInputElement>) => {
        var scale = props.layers[3].scale;
        scale[1] = Number(e.target.value);
        props.setScale(scale);
        if (props.layers[3].downloadChecked===true) {props.setPushButton(false)};
    }
    const scaleChange3 = (e:React.ChangeEvent<HTMLInputElement>) => {
        var scale = props.layers[3].scale;
        scale[2] = Number(e.target.value);
        props.setScale(scale);
        if (props.layers[3].downloadChecked===true) {props.setPushButton(false)};
    }
    const scaleChange4 = (e:React.ChangeEvent<HTMLInputElement>) => {
        var scale = props.layers[3].scale;
        scale[3] = Number(e.target.value);
        props.setScale(scale);
        if (props.layers[3].downloadChecked===true) {props.setPushButton(false)};
    }
    return (
        <div className='corona'>
            {/*<Card>
                <Accordion.Toggle as={Card.Header} eventKey="2">
                    Коронавирус
                    {props.layers.map( (l:any) => {
                    let id:string = l.id;
                    let download:boolean = l.downloadChecked
                    if (id === "corona" && download === true) {return ( <SpinnerForLoading key={id}/> )}              
                })}
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="2">
            */}
                    <Card.Body>
                        <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Label>Выберите территорию</Form.Label>
                            <Form.Control
                                size="sm"
                                as="select"
                                onChange={areaChange}>
                                <option>Россия</option>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="exampleForm.ControlSelect2">
                            <Form.Label>Выберите стиль</Form.Label>
                            <Form.Control
                                size="sm"
                                as="select"
                                onChange={styleChange}>
                                <option>РИК</option>
                                <option>Москва</option>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="formBasicCheckboxCorona">
                            <Form.Check type="checkbox" label="рассчитать на 100 тыс. человек" onChange={checkboxCheck}/>
                        </Form.Group>
                        <Form.Group controlId="exampleForm.ControlSelect3">
                            <Form.Label>Показатель</Form.Label>
                            <Form.Control
                                size="sm"
                                as="select"
                                onChange={valueChange}>
                                <option>Заражения</option>
                                <option>Смерти</option>
                                <option>Выздоровления</option>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="exampleForm.ControlSelect3">
                            <Form.Label>Шкала</Form.Label>
                        </Form.Group>
                        <InputGroup className="mb-3" size="sm">
                            <InputGroup.Prepend>
                                <InputGroup.Text>менее</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl placeholder={props.layers[3].scale[0]} onChange={scaleChange1}/>
                            <FormControl placeholder={props.layers[3].scale[1]} onChange={scaleChange2}/>
                            <FormControl placeholder={props.layers[3].scale[2]} onChange={scaleChange3}/>
                            <FormControl placeholder={props.layers[3].scale[3]} onChange={scaleChange4}/>
                            <InputGroup.Prepend>
                                <InputGroup.Text>более</InputGroup.Text>
                            </InputGroup.Prepend>
                        </InputGroup>
                        <Button
                            size="sm"
                            type="button"
                            disabled={props.layers[3].downloadChecked}
                            onClick={buttonClick}>
                            Скачать</Button>
                    </Card.Body>
                    {/*}
                </Accordion.Collapse>
        </Card>*/}
        </div>
    );
}
const mapStateToProps = (state:any) => {
    return {
        layers: state.layers,
        users: state.users,
    };
};
const matchDispatchToProps = (dispatch:any) => {
    return bindActionCreators({
        setPushButton: pushButton,
        setArea: actionArea,
        setStyle: actionStyle,
        setValue: actionValue,
        setCheckbox: actionCheckbox,
        setScale: actionScale,
        setLyr: actionLayersTocRender,
    }, dispatch);
};

export default connect(mapStateToProps, matchDispatchToProps)(Corona);
