import React from "react";
import { Form } from "react-bootstrap";
import { Chrono } from "react-chrono";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actionLevel } from "../../Toolbox/Cards/Basemap/basemapActions";
import { actionAddBasemaps } from "../../Map/basemaps/actions/actionBasemaps";
import { actionSetYear } from "./actionSetYear";
import { TimelineProps } from "react-chrono/dist/models/TimelineModel";

const HistoricalDateChange: React.FC = (props: any) => {
  const years = [
    2021, 2010, 2005, 2001, 1940, 1939, 1938, 1937, 1935, 1934, 1933, 1932,
    1930, 1920, 1918, 1917, 1913, 1906, 1893,
  ];
  const dateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.setLevel("y" + e.target.value + "_cntpol");
    props.setYear(Number(e.target.value));
    if (e.target.value != "2022") {
      props.setBasemap("https://geo.emg24.ru/geoserver/historical/wms", false);
    } else {
      props.setBasemap("https://geo.emg24.ru/geoserver/baselayers/wms", false);
      props.setLevel("M10");
    }
  };
  return (
    <div style={{ maxHeight: "200px" }}>
      {/*
      <Form.Group controlId="exampleForm.ControlSelect1">
        <Form.Label>Выберите дату</Form.Label>
        <Form.Control size="sm" as="select" onChange={dateChange}>
          <option>2022</option>
          {years.map(function (year) {
            if (year != props.layers[4].level_name) {
              return <option>{year}</option>;
            }
          })}
        </Form.Control>
      </Form.Group>
        */}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    layers: state.layers,
  };
};
const matchDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      setLevel: actionLevel,
      setBasemap: actionAddBasemaps,
      setYear: actionSetYear,
    },
    dispatch
  );
};
export default connect(
  mapStateToProps,
  matchDispatchToProps
)(HistoricalDateChange);
