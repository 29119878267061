import React, { useState } from "react";
import {
  Button,
  Accordion,
  Card,
  InputGroup,
  Form,
  FormControl,
  Badge,
} from "react-bootstrap";
import SpinnerForLoading from "../../../additional/Spinner/SpinnerForLoading";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { pushButton } from "./hydroAction/buttonPush";
import { actionUrl } from "./hydroAction/urlChange";
import { actionLayersTocRender } from "../../../../modules/layers-toc/actionLayerRender";
import axios from "axios";
import { useSelector } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";

const Hydro: React.FC = (props: any) => {
  const [checked, setChecked] = useState(props.layers[6].downloadChecked);

  const users = useSelector((state) => props.users);

    const token = users.currentUser.token;
    const headers = {
      token: token,
    };

  const buttonClick = (e: any) => {
    props.setPushButton(true);
    axios
      .post("/api/hydro", props.layers[7], {
        headers: headers,
      })
      .then((r) => {
        alert(
          "Готово! Все материалы во вкладке Задачи"
        );
        props.setPushButton(false);
        props.setLyr("hydro", true);
      })
      .catch((error) => {
        props.setPushButton(false);
        props.setLyr("hydro", false);
        alert(`Hydro error! ${error.response.data.error}`);
      });
  };
  const addUrl = (e: React.ChangeEvent<HTMLInputElement>) => {
    let url = e.target.value.toString();
    props.setUrl(url);
    if (props.layers[7].downloadChecked === true) {
      props.setPushButton(false);
    }
  };

  return (
    <div className="hydro">
      <Card.Body>
        <h6>Картограмма на Россию</h6>
        <Form.Group controlId="exampleForm.ControlSelect2">
          <Form.Label>Выберите период прогноза (в часах)</Form.Label>
          <Form.Control size="sm" as="select" onChange={addUrl}>
            <option>0-12</option>
            <option>12-24</option>
            <option>24-36</option>
          </Form.Control>
        </Form.Group>
        <Button
          size="sm"
          type="button"
          variant="light"
          href="https://docs.google.com/document/d/15WNnjIsNfOA69L89w3SWbmjY_vJAShiQ2NlrBUWpOZM/edit?usp=sharing"
          target="_blank"
        >
          Инструкция
        </Button>
        <Button
          size="sm"
          type="button"
          variant="light"
          href="https://meteoinfo.ru/"
          target="_blank"
        >
          Открыть Гидромет
        </Button>
        <Button
          size="sm"
          type="button"
          disabled={props.layers[7].downloadChecked}
          onClick={buttonClick}
        >
          Создать
        </Button>
      </Card.Body>
    </div>
  );
};
const mapStateToProps = (state: any) => {
  return {
    layers: state.layers,
    users: state.users,
  };
};
const matchDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      setPushButton: pushButton,
      setUrl: actionUrl,
      setLyr: actionLayersTocRender,
    },
    dispatch
  );
};

export default connect(mapStateToProps, matchDispatchToProps)(Hydro);
