import { Card } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actionAddBasemaps } from "./actions/actionBasemaps";
import { actionLevel } from "../../Toolbox/Cards/Basemap/basemapActions";
import "./index.css";

interface IBasemapCard {
  name: string;
  imgUrl: string;
  url: string;
  setBasemap?: any;
  setLevel?: any;
}

const BasemapCard = (props: IBasemapCard) => {
  const handleClick = (e: any) => {
    props.setBasemap(e.target.id, true);
    props.setLevel("M_osm");
    if (e.target.id === "http://geo.emg24.ru/geoserver/baselayers/wms") {
      props.setBasemap(e.target.id, false);
    }
    if (e.target.id === "https://geo.emg24.ru/geoserver/historical/wms") {
      props.setBasemap(e.target.id, false);
    }
  };
  return (
    <div style={{ width: "100%", margin: "5px" }} onClick={handleClick}>
      <Card.Img variant="top" src={props.imgUrl} id={props.url} />
      <p className="basemapTitle" id={props.url}>
        {props.name}
      </p>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    layers: state.layers,
  };
};

const matchDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      setBasemap: actionAddBasemaps,
      setLevel: actionLevel,
    },
    dispatch
  );
};

export default connect(mapStateToProps, matchDispatchToProps)(BasemapCard);
