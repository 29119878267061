import { combineReducers } from "redux";
import LayersReducers from "./layers";
import userReducer from "./users";
import tasksReducer from "./tasks";
import meteoredReducer from "./meteored";

const allReducers = combineReducers({
  layers: LayersReducers,
  users: userReducer,
  tasks: tasksReducer,
  meteored: meteoredReducer,
});

export default allReducers;
