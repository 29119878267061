import React from "react";
import "./App.css";
import { connect } from "react-redux";
import { QueryClient, QueryClientProvider } from "react-query";
import LeafletMap from "./components/Map/Map";
import Toolbox from "./components/Toolbox/Toolbox";
import Legend from "./components/additional/legend/legend";
import Basemaps from "./components/additional/basemaps/basemaps";

const queryClient = new QueryClient();

const App: React.FC = (props: any) => {
  document.title = "Geo Server";
  return (
    <QueryClientProvider client={queryClient}>
      <div id="app">
        <Toolbox />
        <div id="map">
          <LeafletMap />
          <Legend />
          <Basemaps />
        </div>
      </div>
    </QueryClientProvider>
  );
};

const mapStateToProps = (state: any) => {
  return {
    layers: state.layers,
  };
};

export default connect(mapStateToProps)(App);
