import { useState } from "react";
import { Button, Card } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { pushButton } from "./yandexActions/buttonPush";
import { actionLayersTocRender } from "../../../../modules/layers-toc/actionLayerRender";
import axios from "axios";
import { useSelector } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";

const YandexGeocoder = (props) => {

  const users = useSelector((state) => props.users);

  const [todos, setTodos] = useState([]);
  const [todo, setTodo] = useState("");

  const buttonClick = (e) => {
    console.log(props.layers[16]);
    props.setPushButton(true);
    console.log(props.layers[16]);

    const token = users.currentUser.token;
    const headers = {
      token: token,
    };

    axios
      .post("/api/geocoding", {addresses: todos}, {
        headers: headers,
      })
      .then((r) => {
        alert(
          "Готово! Все материалы во вкладке Задачи"
        );
        props.setLyr("geocoding", true);
        props.setPushButton(false);
      })
      .catch((error) => {
        props.setPushButton(false);
        props.setLyr("hydro", false);
        alert(`Geocoding error!`);
      });
  };

  const addTodo = () => {
    if (todo !== "") {
      setTodos([...todos, todo]);
      setTodo("");
    }
  };

  const deleteTodo = (text) => {
    const newTodos = todos.filter((todo) => {
      return todo !== text;
    });
    setTodos(newTodos);
  };

  return (
    <div className="yandex">
      <Card.Body>
      <h6>Геокодирование по всему миру. Укажите адреса, сформировав список, и нажмите «Создать». Инструмент сформирует один слой со всеми точками. Скачать его можно на вкладке «Слои». Так же автоматически в папку !Py/_GEOCODING_EXP будет скачан shp и тинты для каждой точки под основу TheCity для М24.</h6>
      <InputGroup size="sm" className="mb-3">
        <Form.Control
          placeholder="Добавить адрес"
          aria-label="Small"
          aria-describedby="inputGroup-sizing-sm"
          onChange={(e) => {
            setTodo(e.target.value);
          }}
        />
        <Button size="sm"
          type="button" onClick={addTodo}>
          Добавить
        </Button>
      </InputGroup>

        {todos?.length > 0 ? (
          <ul className="todo-list">
            {todos.map((todo, index) => (
              <div className="todo">
                <li key={index}> {todo} </li>
                {/*
                <Button
                  size="sm"
                  onClick={() => {
                    deleteTodo(todo);
                  }} 
                >
                  Удалить
                </Button>
                */}
              </div>
            ))}
          </ul>
        ) : (
          <div className="empty">
            <p></p>
          </div>
        )}
        <Button
          size="sm"
          type="button"
          variant="light"
          href="https://docs.google.com/document/d/1pJajf6M0tT1iJx0n0mzx-f6vSLRgW9gznwdLoqQulO8/edit?usp=sharing"
          target="_blank"
        >
          Инструкция
        </Button>
        <Button
          size="sm"
          type="button"
          disabled={props.layers[6].downloadChecked}
          onClick={buttonClick}
        >
          Создать
        </Button>
      </Card.Body>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    layers: state.layers,
    users: state.users,
  };
};
const matchDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setPushButton: pushButton,
      setLyr: actionLayersTocRender,
    },
    dispatch
  );
};

export default connect(mapStateToProps, matchDispatchToProps)(YandexGeocoder);
