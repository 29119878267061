export const actionCheckbox = (ext:boolean) => {

    return {
        type: "GISMETEO_EXTENT_CHOOSE",
        payload: { checkboxChecked: ext }
    }
}

export const actionCheckboxCloudRender = (cloud_render:boolean) => {

    return {
        type: "GISMETEO_CLOUD_RENDER",
        payload: { checkboxChecked: cloud_render }
    }
}