import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { Card, CardGroup, Form, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  actionLevel,
  actionCheckboxMapZoom,
} from "../../Toolbox/Cards/Basemap/basemapActions";

const ZoomChange: React.FC = (props: any) => {
  const levels_name = [
    "1:100 000 000",
    "1:50 000 000",
    "1:10 000 000",
    "1:500 000 (данные на Россию + часть СНГ)",
    "1:200 000 (данные на Россию + часть СНГ)",
  ];
  const checkboxCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.setCheckbox(!props.layers[0].zoom_change);
  };

  const levelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    switch (e.target.value) {
      case "1:100 000 000":
        props.setLevel("M10");
        break;
      case "1:50 000 000":
        props.setLevel("M05");
        break;
      case "1:10 000 000":
        props.setLevel("M01");
        break;
      case "1:500 000 (данные на Россию + часть СНГ)":
        props.setLevel("M500");
        break;
      case "1:200 000 (данные на Россию + часть СНГ)":
        props.setLevel("M200");
        break;
      case "Данные OSM":
        props.setLevel("M_osm");
        break;
    }
    console.log(props.layers);
  };
  return (
    <>
      <h6>Данные соответствуют масштабу: {props.layers[4].level_name}</h6>
      <Form.Group controlId="formBasicCheckbox">
        {props.layers[0].year < 2000 ? (
          <Form.Check
            type="checkbox"
            label="ручное переключение масштаба"
            onChange={checkboxCheck}
            disabled
          />
        ) : (
          <Form.Check
            type="checkbox"
            label="ручное переключение масштаба"
            onChange={checkboxCheck}
          />
        )}
      </Form.Group>
      {props.layers.map((l: any) => {
        let zoom_change: boolean = l.zoom_change;
        if (zoom_change === true) {
          return (
            <Form.Group controlId="exampleForm.ControlSelect1">
              <Form.Label>Выберите масштаб</Form.Label>
              {props.layers[0].year <= 2018 ? (
                <Form.Control
                  size="sm"
                  as="select"
                  onChange={levelChange}
                  disabled
                >
                  <option>{props.layers[4].level_name}</option>
                  {levels_name.map(function (name) {
                    if (name != props.layers[4].level_name) {
                      return <option>{name}</option>;
                    }
                  })}
                </Form.Control>
              ) : (
                <Form.Control size="sm" as="select" onChange={levelChange}>
                  <option>{props.layers[4].level_name}</option>
                  {levels_name.map(function (name) {
                    if (name != props.layers[4].level_name) {
                      return <option>{name}</option>;
                    }
                  })}
                </Form.Control>
              )}
            </Form.Group>
          );
        }
      })}
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    layers: state.layers,
  };
};
const matchDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      setLevel: actionLevel,
      setCheckbox: actionCheckboxMapZoom,
    },
    dispatch
  );
};

export default connect(mapStateToProps, matchDispatchToProps)(ZoomChange);
