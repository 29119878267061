import React from 'react';
import { Button, Accordion, Card, Badge, Form } from 'react-bootstrap';
import SpinnerForLoading from '../../../additional/Spinner/SpinnerForLoading';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { pushButton } from "./firesAction/buttonPush";
import { actionSite } from "./firesAction/downloadFromSite";
import axios from 'axios';
import { useSelector } from "react-redux";
import 'bootstrap/dist/css/bootstrap.min.css';

const Fires: React.FC = (props: any) => {
    let fileToUpload: File;

    const users = useSelector((state) => props.users);

    const token = users.currentUser.token;
    const headers = {
      token: token,
    };

    const changeHandler = (event: any) => {
        fileToUpload = event.target.files[0];
    }
    const setCheckbox = (e:React.ChangeEvent<HTMLInputElement>) => {
        switch (props.layers[8].site) {
            case false:            
                props.setCheckbox(true);
                break;
            case true:
                props.setCheckbox(false);
        }
    }

    const buttonClick = (e: any) => {
        props.setPushButton(true);
        if (props.layers[8].site === false) {
            if (fileToUpload == null) {
                alert("Необходимо сначала выбрать файл");
                props.setPushButton(false);
                return
            }
            const formData = new FormData();
            formData.append('file', fileToUpload, fileToUpload.name);
            axios.post("/api/fires?fromsite=0", formData, {
                headers: headers,
              }).then(r => {
                    alert("Готово! Все материалы во вкладке Задачи")
                    props.setPushButton(false);
                }
            ).catch(error => {
                props.setPushButton(false);
                alert(`Fires error! ${ error.response.data.error }`)
            });
        }
        else {
            axios.post("/api/fires?fromsite=1", props.layers[8], {
                headers: headers,
              }).then(r => {
                alert("Готово! Все материалы во вкладке Задачи")
                props.setPushButton(false);
            }
            ).catch(error => {
                props.setPushButton(false);
                alert(`Fires error! ${ error.response.data.error }`)
            });
        }
        }

    return (
        <div className='fires'>
                    <Card.Body>
                    <Form.Group controlId="formBasicCheckbox1">
                         <Form.Check type="checkbox" label="обновить на основе данных сайта" onChange={setCheckbox}/>
                    </Form.Group>
                        <form className="form-group">
                            <label htmlFor="formcontrolFile">Выберите файл для загрузки</label>
                            <input
                                disabled={props.layers[8].site}
                                id="formcontrolFile"
                                className="form-control-file"
                                type="file"
                                name="file"
                                onChange={changeHandler}/>
                        </form>
                        <Button
                            size="sm"
                            type="button"
                            variant="light"
                            href="https://docs.google.com/document/d/1Cmwjzi1hagfOY10pc5ZPdfwUCVTrnqXeiinY5Lqn7Z4/edit?usp=sharing"
                            target="_blank">
                            Инструкция
                        </Button>
                        <Button 
                            size="sm"
                            type="button"
                            variant="light"
                            href="https://aviales.ru/"
                            target="_blank">
                                Открыть Авиалес
                        </Button>
                        <Button
                            size="sm"
                            type="button"
                            disabled={props.layers[8].downloadChecked}
                            onClick={buttonClick}>
                            Загрузить файл</Button>
                    </Card.Body>
        </div>
    );
}
const mapStateToProps = (state:any) => {
    return {
        layers: state.layers,
        users: state.users,
    };
};
const matchDispatchToProps = (dispatch:any) => {
    return bindActionCreators({
        setPushButton: pushButton,
        setCheckbox: actionSite,
    }, dispatch);
};

export default connect(mapStateToProps, matchDispatchToProps)(Fires);
