import React, { useState } from "react";
import {
  Button,
  Accordion,
  Card,
  InputGroup,
  Form,
  FormControl,
} from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { pushButton } from "./shapefileAction/buttonPush";
import { actionName } from "./shapefileAction/nameSet";
import { actionSatellite } from "./shapefileAction/satelliteAdd";
import { actionOmChoose } from "./shapefileAction/omChoose";
import axios from "axios";
import { useSelector } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";

const Shapefile: React.FC = (props: any) => {
  const [checked, setChecked] = useState(props.layers[6].downloadChecked);

  const users = useSelector((state) => props.users);

  const buttonClick = (e: any) => {
    props.setPushButton(true);
    var jbody = props.layers[6];
    jbody.polygons = props.layers[0].polygons; // adding bounds to layer obj

    if (jbody.polygons.length < 1) {
      alert("Ошибка. Для выгрузки шейпфайла нужно выбрать регион.");
      props.setPushButton(false);
      return;
    }

    const token = users.currentUser.token;
    const headers = {
      token: token,
    };

    axios
      .post("/api/shapefile", jbody, {
        headers: headers,
      })
      .then((r) => {
        alert(
          "Готово! Все материалы во вкладке Задачи"
        );
        props.setPushButton(false);
      })
      .catch((error) => {
        props.setPushButton(false);
        alert(`Shapefile error!`);
      });
  };
  const addName = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log(e.target.value);
    let name = e.target.value.toString();
    props.setName(name);
    if (props.layers[6].downloadChecked === true) {
      props.setPushButton(false);
    }
  };
  const omChoose = (e: React.ChangeEvent<HTMLInputElement>) => {
    switch (e.target.value) {
      case "Overmap":
        props.setOm("overmap");
        if (props.layers[6].downloadChecked === true) {
          props.setPushButton(false);
        }
        break;
      case "Overglob":
        props.setOm("overglob");
        if (props.layers[6].downloadChecked === true) {
          props.setPushButton(false);
        }
        break;
    }
    console.log(props.layers);
  };
  const setSat = (e: React.ChangeEvent<HTMLInputElement>) => {
    switch (props.layers[6].satellite) {
      case false:
        props.setSat(true);
        break;
      case true:
        props.setSat(false);
    }
  };

  return (
    <div className="shapefile">
      <Card.Body>
        <InputGroup size="sm" className="mb-3">
          <InputGroup.Prepend>
            <InputGroup.Text id="inputGroup-sizing-sm">
              Имя файла
            </InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            aria-label="Small"
            aria-describedby="inputGroup-sizing-sm"
            placeholder={"New_shape"}
            onChange={addName}
          />
        </InputGroup>
        <Form.Group controlId="exampleForm.ControlSelect1">
          <Form.Label>Выберите программу</Form.Label>
          <Form.Control size="sm" as="select" onChange={omChoose}>
            <option>Overmap</option>
            <option>Overglob</option>
          </Form.Control>
        </Form.Group>
        <Button
          size="sm"
          type="button"
          variant="light"
          href="https://docs.google.com/document/d/1sGrtKGdwANEcjF2YwumhDFbSFc5gUKGthkkvHAZc6tI/edit?usp=sharing"
          target="_blank"
        >
          Инструкция
        </Button>
        <Button
          size="sm"
          type="button"
          disabled={props.layers[6].downloadChecked}
          onClick={buttonClick}
        >
          Создать
        </Button>
      </Card.Body>
    </div>
  );
};
const mapStateToProps = (state: any) => {
  return {
    layers: state.layers,
    users: state.users,
  };
};
const matchDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      setPushButton: pushButton,
      setName: actionName,
      setOm: actionOmChoose,
      setSat: actionSatellite,
    },
    dispatch
  );
};

export default connect(mapStateToProps, matchDispatchToProps)(Shapefile);
