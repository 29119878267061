import React, { useState } from "react";
import { Accordion, Card, Form } from "react-bootstrap";
import { actionTool } from "../Tools/toolAction/actionTool";
import SpinnerForLoading from "../../additional/Spinner/SpinnerForLoading";
import Basemap from "../Cards/Basemap/Basemap";
import Gismeteo from "../Cards/Gismeteo/Gismeteo";
import Corona from "../Cards/Coronavirus/Corona";
import Radar from "../Cards/Radar/Radar";
import Meteored from "../Cards/Meteored/Meteored";
import Shapefile from "../Cards/Shapefile/Shapefile";
import Hydro from "../Cards/Hydro/Hydro";
import Satellite from "../Cards/Satellite/Satellite";
import Fires from "../Cards/Fires/Fires";
import Firms from "../Cards/Firms/Firms";
import Temperature from "../Cards/Temperature MSK/Temperature";
import YandexGeocoder from "../Cards/Yandex/YandexGeocode";
import Vybory2021 from "../Cards/Vybory2021/Vybory2021";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "bootstrap/dist/css/bootstrap.min.css";

const Tools: React.FC = (props: any) => {
  const isAuth = props.users.isAuth;

  const toolChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    switch (e.target.value) {
      case "Атмосфера (осадки)":
        //console.log('etr')
        props.setTool("Gismeteo");
        break;
      case "Атмосфера (температура)":
        props.setTool("temperature");
        break;
      case "Коронавирус":
        props.setTool("corona");
        break;
      case "Погода Meteored":
        props.setTool("meteored");
        break;
      case "Пожары Авиалесохрана":
        props.setTool("fires");
        break;
      case "Пожары FIRMS":
        props.setTool("firms");
        break;
      case "Спутник для Overmap":
        props.setTool("sat");
        break;
      case "Шейпфайл для Overmap/Overglobe":
        props.setTool("shp");
        break;
      case "Радар":
        props.setTool("radar");
        break;
      case "Гидрологический прогноз (опасные явления)":
        props.setTool("hydro");
        break;
      case "Геокодирование":
        props.setTool("geocoding");
        break;
      case "Экспорт карты":
        props.setTool("export_map");
        break;
      case "Выборы":
        props.setTool("vybory");
        break;
    }
    console.log(props.layers[11]);
  };
  return (
    <div className="tools">
      <Card.Body>
        <Form.Group controlId="exampleForm.ControlSelect1">
          <Form.Label>Выберите инструмент</Form.Label>
          <Form.Control size="sm" as="select" onChange={toolChange}>
            <option>Геокодирование</option>
            {isAuth ? <option>Атмосфера (осадки)</option> : null}
            {isAuth ? <option>Атмосфера (температура)</option> : null}
            {/*<option>Выберите инструмент</option>*/}
            {isAuth ? <option>Выборы</option> : null}
            <option>Гидрологический прогноз (опасные явления)</option>
            <option>Коронавирус</option>
            <option>Погода Meteored</option>
            <option>Пожары Авиалесохрана</option>
            <option>Пожары FIRMS</option>
            <option>Спутник для Overmap</option>
            <option>Шейпфайл для Overmap/Overglobe</option>
            <option>Экспорт карты</option>
          </Form.Control>
        </Form.Group>
        <Card>
          {props.layers.map((l: any) => {
            let tool: string = l.toolChange;
            if (tool === "Gismeteo") {
              return <Gismeteo />;
            } else if (tool === "temperature") {
              return <Temperature />;
            } else if (tool === "hydro") {
              return <Hydro />;
            } else if (tool === "fires") {
              return <Fires />;
            } else if (tool === "firms") {
              return <Firms />;
            } else if (tool === "sat") {
              return <Satellite />;
            } else if (tool === "shp") {
              return <Shapefile />;
            } else if (tool === "meteored") {
              return <Meteored />;
            } else if (tool === "corona") {
              return <Corona />;
            } else if (tool === "radar") {
              return <Radar />;
            } else if (tool === "geocoding") {
              return <YandexGeocoder />;
            } else if (tool === "export_map") {
              return <Basemap />;
            } else if (tool === "vybory") {
              return <Vybory2021 />;
            }
          })}
        </Card>
      </Card.Body>
    </div>
  );
};
const mapStateToProps = (state: any) => {
  return {
    layers: state.layers,
    users: state.users,
  };
};
const matchDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      setTool: actionTool,
    },
    dispatch
  );
};

export default connect(mapStateToProps, matchDispatchToProps)(Tools);
